import "./style.scss";

function Header() {
  return (
    <header className="header">
      <div className="navs"></div>
    </header>
  );
}

export default Header;
